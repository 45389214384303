import React from "react";
import Layout from "../components/layout/Layout";
import ServicesContent from "../components/ServicesContent";

const Services = () => {
  return <>
      <Layout title={'Services'}>
          <ServicesContent/>
      </Layout>
  </>
}
export default Services;